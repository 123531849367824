import { Box, Dialog, DialogContent, Typography } from '@mui/material'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChipCus from '../../../components/chip/chip.cus';
import { ReducersModal } from '../../../modal';
import { getAssignFormularySchemeList } from '../action';
type SchemeViewDialogProps = {
    open: boolean;
    handleClose: () => void;
    FormularyId:number
    };

function SchemeViewDialog({open, handleClose, FormularyId}: SchemeViewDialogProps) {
    const dispatch = useDispatch();
    const {Page} = useSelector((state: ReducersModal) => state.manageAssignFormularytoSchemeReducer);
    const { SchemeFromAPI } = useSelector((state: ReducersModal) => state.assignFormularytoSchemeReducer);
        
    useEffect(()=>
    {
        if(open)
        {
            dispatch(getAssignFormularySchemeList({page:Page, limit:10000, formularyId:Number(FormularyId), type:undefined}))
        }
    },[FormularyId, dispatch, open])  
  return (
    <Dialog
        open={open}
        onClose={handleClose}
        fullWidth maxWidth="md"
        scroll={'paper'} sx={{ zIndex: 1200 }}
        >
        <Box sx={{ bgcolor: 'primary.main', p: 1.5, textAlign: 'center' }}>
            <Typography variant="h1" sx={{ color: 'common.white' }}>
            Schemes
            </Typography>
        </Box>
        <DialogContent>
        {SchemeFromAPI?.length > 0 && (
            <Box>
            {SchemeFromAPI.map((item) => {
                return (
                <ChipCus
                    sx={{ mt: 0.8, mr: 0.5 }}
                    key={item.SchemeName}
                    label={item.SchemeName}
                    color="primary"
                    />
                );
            })}
            </Box>
        )}
        </DialogContent>
    </Dialog>
  )
 }   

export default SchemeViewDialog