import { Box, TableContainer, Table, TableHead, TableBody, Typography } from '@mui/material';
import TableCellWrapper from '../../components/table/table.cell.wrapper';
import TableRowWrapper from '../../components/table/table.row.wrapper';
import LOCAL_IMAGES from '../../utils/local.images';
import PaginateWrapper from '../../components/paginate-wrapper/paginate.wrapper';
import { manageFormularyIcd10DiagnosisBlockListAction, manageSchemeSpecificFormularyListAction } from './action';
import { ReducersModal } from '../../modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ROUTES_NAME from '../../utils/routes.name';
import { formatDateAndTime } from '../../utils/common.function';
import DeleteButton from '../../components/button/delete.button';
import ConfirmButton from '../../components/button/confirm.button';
import EditButton from '../../components/button/edit.button';
import { TableSort, TSort } from '../../types/global.types';
import { Formulary, WNavigateComName } from '../../types/manage.scheme.specific.formulary';
import ACTION_NAME from '../../utils/action.name';
import useGlobalStyle from '../../hooks/use.global.style';
import CopyButton from '../../components/button/copy.button';

type SchemeTableProps = {
  data: Formulary[];
  handleOpenInactive: (item: Formulary) => void;
  pageCount: number;
  handlePageClick: (selectedItem: { selected: number }) => void;
  rowPerPage: number;
  totalRecord: number;
  page: number;
  handlePrevious?: () => void;
  handleNext?: () => void;
  activePage?: number;
  handleOpenEditBlock: (type: string, item: Formulary) => void;
  handleOpenCopyBlock:()=>void;
};

function SchemeSpecificFormularyTable({
  data,
  handleOpenInactive,
  pageCount,
  handlePageClick,
  totalRecord,
  page,
  rowPerPage,
  handlePrevious,
  handleNext,
  activePage,
  handleOpenEditBlock,
  handleOpenCopyBlock,
}: SchemeTableProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const globalClasses = useGlobalStyle();
  const { SortOrder } = useSelector((state: ReducersModal) => state.manageSchemeSpecificFormularyReducer);

  const handleAscAndDesc = (SortBy: TSort) => {
    dispatch(
      manageSchemeSpecificFormularyListAction({
        ...(SortOrder === 'ASC' ? { SortOrder: 'DESC' } : { SortOrder: 'ASC' }),
        ...{ SortBy: SortBy }
      })
    );
  };

  const navigateToDetail = (formularyId: number, navigateComponentName?: WNavigateComName) => {
    dispatch(manageFormularyIcd10DiagnosisBlockListAction({ StatusId:"" }))
    dispatch({
      type: ACTION_NAME.SCHEME_DETAIL_RESET
    });
    navigate(`${ROUTES_NAME.MANAGE_SCHEME_SPECIFIC_FORMULARY_DETAIL}/${formularyId}`, {
      state: navigateComponentName
    });
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.common.white,
          py: 1.5,
          borderRadius: (theme) => theme.spacing(0.8)
        }}>
        <TableContainer component={Box}>
          <Table sx={{ minWidth: 800 }}>
            <TableHead>
              <TableRowWrapper>
                <TableCellWrapper>S.No.</TableCellWrapper>
                <TableCellWrapper>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <Typography
                      sx={{
                        font: 'inherit'
                      }}>
                      Formulary Name
                    </Typography>
                    <Box
                      sx={{ ml: 0.5, cursor: 'pointer' }}
                      onClick={() => {
                        handleAscAndDesc(TableSort.BlockName);
                      }}>
                      <img src={LOCAL_IMAGES.UP_DOWN_FILTER} alt="" />
                    </Box>
                  </Box>
                </TableCellWrapper>
                <TableCellWrapper>Insurance Company</TableCellWrapper>
                <TableCellWrapper>ICD10 Code(s) Block</TableCellWrapper>
                <TableCellWrapper>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <Typography
                      sx={{
                        font: 'inherit'
                      }}>
                      Created At
                    </Typography>
                    <Box
                      sx={{ ml: 0.5, cursor: 'pointer' }}
                      onClick={() => {
                        handleAscAndDesc(TableSort.CreatedAt);
                      }}>
                      <img src={LOCAL_IMAGES.UP_DOWN_FILTER} alt="" />
                    </Box>
                  </Box>
                </TableCellWrapper>
                <TableCellWrapper>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <Typography
                      sx={{
                        font: 'inherit'
                      }}>
                      Updated At
                    </Typography>
                    <Box
                      sx={{ ml: 0.5, cursor: 'pointer' }}
                      onClick={() => {
                        handleAscAndDesc(TableSort.UpdatedAt);
                      }}>
                      <img src={LOCAL_IMAGES.UP_DOWN_FILTER} alt="" />
                    </Box>
                  </Box>
                </TableCellWrapper>
                <TableCellWrapper>Status</TableCellWrapper>
                <TableCellWrapper>Action</TableCellWrapper>
              </TableRowWrapper>
            </TableHead>
            <TableBody>
              {data?.length > 0 ? (
                data.map((item: Formulary, index: number) => {
                  return (
                    <TableRowWrapper
                      sx={{ cursor: 'pointer' }}
                      hover
                      key={item?.FormularyId}
                      onClick={() => navigateToDetail(item.FormularyId)}>
                      <TableCellWrapper>{index + page}</TableCellWrapper>
                      <TableCellWrapper>{item.Name || 'N/A'}</TableCellWrapper>
                      <TableCellWrapper>{item.InsuranceCompanyName || 'N/A'}</TableCellWrapper>
                      <TableCellWrapper>
                        {item.BlockCount ? (
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              navigateToDetail(item.FormularyId, 'icd');
                            }}
                            className={globalClasses.span}>
                            {item.BlockCount}
                          </span>
                        ) : (
                          item.BlockCount
                        )}
                      </TableCellWrapper>
                      <TableCellWrapper>
                        <>{formatDateAndTime(item.CreatedAt) || 'N/A'}</>
                      </TableCellWrapper>
                      <TableCellWrapper>
                        <>{item.ModifiedAt !== null ? formatDateAndTime(item.ModifiedAt) : 'N/A'}</>
                      </TableCellWrapper>
                      <TableCellWrapper>{item.IsActive === 1 ? 'Active' : 'In Active'}</TableCellWrapper>
                      <TableCellWrapper>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <CopyButton
                            title='Copy'
                            onClick={(e) => {
                              e.stopPropagation();
                              dispatch({
                                type:ACTION_NAME.COPY_SCHEME_SPECIFIC_FORMULARY,
                                payload:{
                                  FromNewFormulary: false,
                                  SourceFormulary:item?.FormularyId,
                                  FormularyToRemove:[item?.FormularyId],
                                  FromAssignScheme:false

                                }
                              })
                              handleOpenCopyBlock();
                            }}
                          />
                          <Box
                            sx={{
                              width: (theme) => theme.spacing(0.1),
                              backgroundColor: 'var(--table-delete-edit-middle-line)',
                              alignSelf: 'stretch',
                              mx: 0.5
                            }}></Box>
                          <EditButton
                            title='Edit'
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpenEditBlock('edit', item);
                            }}
                          />
                          <Box
                            sx={{
                              width: (theme) => theme.spacing(0.1),
                              backgroundColor: 'var(--table-delete-edit-middle-line)',
                              alignSelf: 'stretch',
                              mx: 0.5
                            }}></Box>

                          {item.IsActive === 1 && (
                            <DeleteButton
                              title='Suspend'
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOpenInactive(item);
                              }}
                            />
                          )}
                          {item.IsActive === 0 && (
                            <ConfirmButton
                              title='Activate'
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOpenInactive(item);
                              }}
                            />
                          )}
                        </Box>
                      </TableCellWrapper>
                    </TableRowWrapper>
                  );
                })
              ) : (
                <TableRowWrapper>
                  <TableCellWrapper colSpan={9}>No data found</TableCellWrapper>
                </TableRowWrapper>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ mt: 2 }}>
        {data?.length > 0 && (
          <PaginateWrapper
            activePage={activePage}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            page={page}
            rowpPerPage={rowPerPage}
            totalRecord={totalRecord}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
          />
        )}
      </Box>
    </>
  );
}

export default SchemeSpecificFormularyTable;
