import { Box, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import ButtonWrapper from '../../../components/button/button.wrapper';
import ModalWrapper from '../../../components/modal-wrapper/modal.wrapper';
import { IcdMedicineList } from '../../../types/manage.icd10.diagnosis.block';
import AutocomAddMedicine from './autocom.add .medicine';
import showAlert from '../../../utils/alert';
import { convertAddMedicinePayload } from '../../../utils/common.function';
import { ReducersModal } from '../../../modal';
import { useSelector, useDispatch } from 'react-redux';
import { addMedicineApi, getMedicineList, icdDetail } from '../action';
import CONSTANTS from '../../../utils/constants';
import { globalLoaderEnd, globalLoaderStart } from '../../../reducer/global.reducer';

type AddMedicineProps = {
  open: boolean;
  handleClose: () => void;
};

function AddMedicine({ open, handleClose }: AddMedicineProps) {
  const dispatch = useDispatch();
  const [addMedicine, setAddMedicine] = useState<IcdMedicineList[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [medicineListClose, setMedicineListClose] = useState(false);
  const { BlockId } = useSelector((state: ReducersModal) => state.icdDetailReducer);
  const { isLoading } = useSelector((state: ReducersModal) => state.globalLoaderReducer);
  const { Page, ItemOffset, SortBy, SortOrder } = useSelector((state: ReducersModal) => state.icdMedicineReducer);

  useEffect(() => {
    if (open) setAddMedicine([]);
  }, [open]);

  const validateMedicine = () => {
    if (addMedicine.length > 0) return false;
    return true;
  };

  const handleAddMedicine = () => {
    const isMedicine = validateMedicine();
    if (isMedicine || !BlockId) return showAlert(2, 'Please select minimum one medicine');
    const payload = convertAddMedicinePayload(BlockId, addMedicine);
    dispatch(globalLoaderStart());
    addMedicineApi(payload)
      .then(({ status }) => {
        if (status !== CONSTANTS.API_SUCCESS_CODE.POST_SUCCESS) return;
        showAlert(1, 'Medicine added successfully');
        dispatch(globalLoaderEnd());
        dispatch(icdDetail(String(BlockId)));
        dispatch(
          getMedicineList({
            blockId: String(BlockId),
            page: Page,
            limit: ItemOffset,
            ...(SortBy && SortOrder && { sortBy: SortBy, sortOrder: SortOrder })
          })
        );
        handleClose();
      })
      .catch(() => {
        dispatch(globalLoaderEnd());
        showAlert(2);
      });
  };

  const handleCloseModal = () => {
    setMedicineListClose(false);
    setAddMedicine([]);
    handleClose();
  };

  const handleMedicineListClose = () => {
    setMedicineListClose(true);
  };

  return (
    <ModalWrapper
      open={open}
      handleClose={handleCloseModal}
      sx={{
        width: { xs: 300, sm: 650 },
        boxShadow: 'var(--link-box-shadow)'
      }}>
      <Box sx={{ bgcolor: 'primary.main', p: 1.5, textAlign: 'center' }} onClick={handleMedicineListClose}>
        <Typography variant="h1" sx={{ color: 'common.white' }}>
          Add Medicine
        </Typography>
      </Box>
      <Box sx={{ p: 2 }}>
        <Box sx={{ mb: isDropdownOpen?32:6 }}>
          <AutocomAddMedicine
            setMedicineListClose={setMedicineListClose}
            medicineListClose={medicineListClose}
            setAddMedicine={setAddMedicine}
            label="Search brand by molecule name"
            setIsDropdownOpen= {setIsDropdownOpen}
          />
          
        </Box>
        <Box
          onClick={handleMedicineListClose}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <ButtonWrapper
            variant="outlined"
            sx={{
              color: (theme) => theme.palette.error.light,
              flexBasis: '48%',
              borderColor: (theme) => theme.palette.error.light,
              '&:hover': {
                borderColor: (theme) => theme.palette.error.light
              }
            }}
            disabled={isLoading}
            onClick={handleClose}>
            Cancel
          </ButtonWrapper>
          <ButtonWrapper
            disabled={isLoading}
            onClick={handleAddMedicine}
            variant="contained"
            color="primary"
            sx={{ flexBasis: '48%' }}>
            {isLoading ? 'Loading ...' : 'Save'}
          </ButtonWrapper>
        </Box>
      </Box>
    </ModalWrapper>
  );
}

export default AddMedicine;
