import { Autocomplete, createFilterOptions } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../../components/form-ui/textfield-wrapper/input.field";
import useDebounce from "../../../hooks/use.debounce";
import { ReducersModal } from "../../../modal";
import { Block } from "../../../types/manage.icd10.diagnosis.block";
import ACTION_NAME from "../../../utils/action.name";

import showAlert from "../../../utils/alert";
import { getFormularyBlockList } from "../action";
type AutocomBlockProps = {
    label: string;
    InsuranceCompanyId:number;
    FormularyId:number;
     };

function AutocomBlock({label, InsuranceCompanyId, FormularyId}:AutocomBlockProps) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<Block[]>([]);
    const [inputValue, setInputValue] = useState('');
    const debounceValue = useDebounce(inputValue, 500);
    const {FromBlockId, ToBlocks} = useSelector((state:ReducersModal)=>state.copySchemeSpecificFormularyBlockReducer)
    const dispatch = useDispatch()
    useEffect(() => {
        if (!open) {
          setOptions([]);
        }
      }, [open]);

      useEffect(() => {
        let active = true;
        if (!debounceValue || debounceValue.length < 3) {
          return undefined;
        }
        if (InsuranceCompanyId === 0) return;
        (async () => {
            try {
              const res = await getFormularyBlockList({
                page: 1,
                limit: 20,
                search: debounceValue,
                insuranceCompanyId: InsuranceCompanyId,
                formularyId: Number(FormularyId)
              });
              if (active) {
                const newBlock: Block[] = [];
                for (const ele of res.data.Data.Blocks) {
                    newBlock.push({
                    ...ele,
                    inputV: debounceValue,
                    });
          }
          setOptions(newBlock.filter((block)=> !FromBlockId.includes(block.BlockId)));
              }
            } catch (_err) {
              showAlert(2);
            }
          })();
      
          return () => {
            active = false;
          };
    
        }, [debounceValue]);
        const filterOptions = createFilterOptions({
          stringify: (option:any) => `${option.Name} ${option?.inputV}`
        });
      
    return (
        <Autocomplete
        filterOptions={filterOptions}
        size="small"
        noOptionsText={debounceValue.length >= 3 ? 'No Block found' : 'Type at least 3 characters'}
        onChange={(_e, newValue) => {
            if (newValue) {
            // eslint-disable-next-line no-console
            console.log(newValue)
            dispatch({
                type:ACTION_NAME.COPY_SCHEME_SPECIFIC_FORMULARY_BLOCK,
                payload:{
                    ToBlocks: [...ToBlocks, newValue],
                    FromBlockId:[...FromBlockId, newValue.BlockId]
                }
            })
            }
        }}
        onInputChange={(_event, newInputValue, reason) => {
            if (reason === 'reset') setInputValue('');
            else setInputValue(newInputValue);
          }}
          inputValue={inputValue}
        open={open}
        onOpen={() => {
            setOpen(true);
        }}
        onClose={() => {
            setOpen(false);
        }}
        getOptionLabel={(option) => option.Name}
        options={options}
        renderInput={(params) => (
            <InputField
            {...params}
            label={label}
            InputProps={{
                ...params.InputProps,
                endAdornment: <Fragment>{params.InputProps.endAdornment}</Fragment>,
                autoComplete: 'off'
            }}
            />
        )}
        /> )
}

export default AutocomBlock