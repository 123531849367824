import { Autocomplete, Box } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../../../components/form-ui/textfield-wrapper/input.field";
import useDebounce from "../../../../hooks/use.debounce";
import { ReducersModal } from "../../../../modal";
import { Formulary } from "../../../../types/manage.scheme.specific.formulary";
import ACTION_NAME from "../../../../utils/action.name";
import showAlert from "../../../../utils/alert";
import { getSchemeSpecificFormularyListForAutoCom } from "../../action";

type AutocomWrapProps = {
  name: string;
  label: string;
  };
function AutocomFormularySearch({name, label}:AutocomWrapProps) {
  const [open, setOpen] = useState(false);
  const [formularyList, setFormularyList] = useState<Formulary[]>([])
  const [value, setValue] = useState({} as Formulary);
  const [inputValue, setInputValue] = useState('');
  const debouncedValue = useDebounce(inputValue.trim(), 1000);
  const {DestinationInsuranceId, DestinationFormulary, FormularyToRemove, DestinationFormularyId} = useSelector((state: ReducersModal) => state.copySchemeSpecificFormularyReducer)
  const dispatch = useDispatch()  
  useEffect(() => {
    if (!open) {
      setFormularyList([]);
    }
  }, [open]);
    useEffect(() => {
      let active = true;
      if (!debouncedValue || debouncedValue.length < 3) {
        return undefined;
      }
      (async () => {
          try {
            const res = await getSchemeSpecificFormularyListForAutoCom({
              page: 1,
              limit: 20,
              filterValue: debouncedValue,
              forScheme:false
            });
            if (active) {
              const newFormulary: Formulary[] = [];
              for (const ele of res.data.Data.Formulary) {
                newFormulary.push({
                  ...ele,
                  inputV: debouncedValue,
                  });
        }
        setFormularyList(newFormulary.filter((formulary)=> !FormularyToRemove.includes(formulary.FormularyId)));
            }
          } catch (_err) {
            showAlert(2);
          }
        })();
    
        return () => {
          active = false;
        };
  
      }, [debouncedValue]);
    
    return (
        <Autocomplete
          value={Object.keys(value).length === 0 ? null : value}
          size="small"
          options={formularyList}
          noOptionsText={debouncedValue.length >= 3 ? 'No Formulary found' : 'Type at least 3 characters'}
          onInputChange={(_event, newInputValue, reason) => {
            if (reason === 'reset') setInputValue('');
            else setInputValue(newInputValue);
          }}
          inputValue={inputValue}
          onChange={(_e, newValue) => {
            if (!newValue) return;
            setValue(newValue);
            dispatch({
              type:ACTION_NAME.COPY_SCHEME_SPECIFIC_FORMULARY,
              payload:{
                FormularyToRemove:[...FormularyToRemove, newValue.FormularyId],
                DestinationFormulary:[...DestinationFormulary, newValue],
                DestinationInsuranceId:[...DestinationInsuranceId, newValue.InsuranceCompanyId],
                DestinationFormularyId:[...DestinationFormularyId, newValue.FormularyId]

              }
            })
            
          }}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
              setOpen(false);
          }}
          autoHighlight
          isOptionEqualToValue={(option, value) => option.Name === value.Name}
          getOptionLabel={(option) => `${option.Name}-${option.InsuranceCompanyName}`}
          renderOption={(props, option) => {
            return (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.Name} - {option.InsuranceCompanyName}
              </Box>
            );
          }}
          renderInput={(params) => (
            <InputField
              name={name}
              {...params}
              label={label}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'off'
              }}
            />
          )}
        />
      );
  }

export default AutocomFormularySearch